@import '../../../core/variables';

@media (max-width: 576px) {
  .contained {
    display: none;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .contained {
    display: none;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .contained {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: visible;
    border-bottom: 1px solid #aea8b3;
    width: 95%;
    margin: 0 auto;
    max-width: 1280px;

    .menu {
      width: 100%;
      height: 58px;
      flex-wrap: nowrap;
      display: flex;
      overflow: auto;
      align-items: center;
      justify-content: left;

      a {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        height: 100%;
        display: flex;
        align-items: center;
        margin-right: 30px;
        color: #403845;
        white-space: nowrap;
      }

      a.selected {
        border-bottom: 2px solid #3547fd;
        color: #3547fd;
      }
    }
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
}
