@import '../core/variables';

@media (max-width: 576px) {
  .mobileMenuMain {
    padding-top: 50px;
    top: 0;
    a {
      width: 100%;
    }

    .menuWrapper {
      display: flex !important;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;

      .menuLogout {
        margin-top: 30px;
        border-bottom: 1px solid #C4C4C4;
        cursor: pointer;
        padding: 15px 24px;
      }

      .account {
        border-bottom: 1px solid #AEA8B3;
        margin-bottom: 24px;
        padding-bottom: 24px;

        .title {
          font-family: 'Gilroy';
          font-style: normal;
          font-weight: 800;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.01em;
          text-transform: uppercase;
          color: #000000;
          padding: 15px 24px 12px;
        }
      }
    }

    .children {
      a {
        padding: 12px 24px 12px 40px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #605866;
      }
    }
  }

  .logo {
    width: 110px;
    height: 33px;
    margin-left: 18px;
  }

  .menu {
    display: none;
  }

  .loginBtn {
    display: none;
  }

  .mobileMenu {
    display: inline-block;
    padding: 4px 4px 4px 4px;
    height: 100%;
    margin-left: 21px;
  }

  .leftWrapper {
    display: flex;
    justify-content: center;
  }

  .rightBar {
    padding-right: 0;
  }

  .topBar {
    height: 57px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #3547FD;
    z-index: 999;
    position: relative;
  }

  .searchIcon {
    display: inline-block;
    padding: 8px;
    margin-right: 20px;
    cursor: pointer;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .userButton {
    display: none;
    background: url(/images-local/icon/user-big-white.svg) no-repeat center;
    width: 40px;
    height: 40px;
    margin-bottom: 3px;
    cursor: pointer;
    margin-left: 20px;
    margin-right: 15px;
  }
  .mobileMenuMain {
    padding-top: 50px;
    top: 0;
    a {
      width: 100%;
    }

    .menuWrapper {
      display: flex !important;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;

      .menuLogout {
        margin-top: 30px;
        border-bottom: 1px solid #C4C4C4;
        cursor: pointer;
        padding: 15px 24px;
      }

      .account {
        border-bottom: 1px solid #AEA8B3;
        margin-bottom: 24px;
        padding-bottom: 24px;
        .title {
          font-family: 'Gilroy';
          font-style: normal;
          font-weight: 800;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.01em;
          text-transform: uppercase;
          color: #000000;
          padding: 15px 24px 12px;
        }
      }
    }

    .children {
      a {
        padding: 14px 24px 14px 40px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #605866;
      }
    }
  }

  .logo {
    width: 110px;
    height: 33px;
    margin-left: 18px;
  }

  .menu {
    display: none;
  }

  .mobileMenu {
    display: inline-block;
    padding: 4px 4px 4px 4px;
    height: 100%;
    margin-left: 21px;
  }

  .leftWrapper {
    display: flex;
    justify-content: center;
  }

  .logo {
    margin-left: 27px;
  }
  .rightBar {
    padding-right: 0;
  }

  .topBar {
    height: 57px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #3547FD;
    z-index: 999;
    position: relative;
  }

  .searchIcon {
    display: inline-block;
    padding: 8px;
    margin-right: 20px;
    cursor: pointer;
  }

  .loginBtn {
    cursor: pointer;
    display: none;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.01em;
    padding: 10px 31px;
    color: $white;
    border: 1px solid $white;
    box-sizing: border-box;
    margin-left: 30px;
    margin-right: 20px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .menu {
    display: none;
  }
  .loginBtn {
    display: inline-block;
  }
  .userButton {
    display: inline-block;
  }

  .mobileMenu {
    display: inline-block;
    padding: 4px 4px 4px 4px;
    height: 100%;
    margin-left: 21px;
    margin-top: 8px;
  }

  .leftWrapper {
    display: flex;
    justify-content: center;
  }

  .topBar {
    height: 57px;
    background: #3547FD;
  }

  .logo {
    margin-left: 27px;
  }

  .logo {
    width: 160px;
    height: 48px;
    margin-left: 26px;
    cursor: pointer;
  }

  .topBar {
    height: 65px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .rightBar {
    display: flex;
    align-items: center;
    padding-right: 24px;
  }

  .searchIcon {
    display: inline-block;
    padding: 8px;
    cursor: pointer;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .logo {
    width: 160px;
    height: 48px;
    margin-left: 40px;
    cursor: pointer;
  }
  .menu {
    list-style-type: none;
    display: flex;
    width: 50%;
    position: relative;
    font-weight: 500;

    > li {
      margin-left: 30px;
      position: relative;

      a {
        line-height: 19px;
        font-size: 16px;
        font-family: Gilroy;
        font-style: normal;
        font-weight: normal;
        color: #ffffff;
      }

      .children {
        display: none;
        position: absolute;
        width: 150%;
        background: #fff;
        box-shadow: 8px 8px 16px rgba(153, 153, 153, 0.12);
        padding: 10px 0;

        li {
          a {
            display: inline-block;
            padding: 0 24px;
            line-height: 32px;
            color: #605866;
            font-size: 14px;
            font-weight: 500;
          }
        }

        li:hover {
          background: rgba(96, 110, 255, 0.1);

          a {
            color: #3547FD;
          }
        }
      }

      &:hover {
        .children {
          display: block;
        }
      }
    }

    .selected {
      a {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #3547FD;
      }

      a:before {
        content: "";
        position: absolute;
        left: 5px;
        bottom: -11px;
        height: 2px;
        width: 33%;
        border-bottom: 2px solid #3547FD;
      }
    }
  }

  .rightBar {
    margin-top: 0;
  }

  .mobileMenu {
    display: none;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
}
