@import '../../core/variables';


@media (max-width: 576px) {
  .logo {
    display: none;
  }
  .welcomeBlock {
    display: block;
    margin: 25px auto;
    max-width: 85%;
  }

  .ruka {
    position: absolute;
    bottom: -30px;
    left: -20px;
    max-width: 390px;
    width: 97%;
  }

  .logoBig {
    max-width: 100%;
  }

  .welcomeTitle {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 72px;
    color: #FFFFFF;
    margin-top: 4px;
  }

  .welcomeDescription {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #F1EBF5;
    margin-top: 20px;

    > span {
      max-width: 90%;
    }
  }

  .header {
    height: 380px;
    width: 100%;
    background: $blue;
    position: relative;
  }

  .welcomePreTitle {
    text-align: left;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 0;
    color: #FFFFFF;
  }
}

@media (max-width: 330px) {
  .ruka {
    position: absolute;
    bottom: -27px;
    left: -20px;
    max-width: 390px;
    width: 115%;
  }

  .header {
    height: 330px;
    width: 100%;
    background: $blue;
    position: relative;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .logo {
    display: none;
  }
  .welcomeBlock {
    display: block;
    margin: 25px auto;
    max-width: 75%;
  }

  .ruka {
    position: absolute;
    bottom: -30px;
    left: -20px;
    max-width: 330px;
    width: 97%;
  }

  .logoBig {
    max-width: 100%;
  }

  .welcomeTitle {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 72px;
    color: #FFFFFF;
    margin-top: 4px;
  }

  .welcomeDescription {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #F1EBF5;
    margin-top: 20px;

    > span {
      max-width: 90%;
    }
  }

  .header {
    height: 380px;
    width: 100%;
    background: $blue;
    position: relative;
  }

  .welcomePreTitle {
    text-align: left;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 0;
    color: #FFFFFF;
  }


}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .ruka {
    position: absolute;
    left: -27px;
    max-width: 600px;
    display: block;
    bottom: -40px;
    width: 60%;
  }

  .welcomeBlock {
    display: block;
    position: absolute;
    top: 15%;
    right: 7%;
    max-width: 40%;
  }

  .welcomeTitle {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 72px;
    color: #FFFFFF;
    margin-top: 4px;
  }

  .welcomeDescription {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #F1EBF5;
    margin-top: 20px;

    > span {
      max-width: 90%;
    }
  }

  .header {
    position: relative;
    height: 400px;
    width: 100%;
    background: $blue;
  }

  .welcomePreTitle {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    text-align: left;
  }

  .loginBtn {
    display: none;
    color: $white;
    border: 1px solid $white;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .ruka {
    position: absolute;
    left: -27px;
    max-width: 600px;
    display: block;
    bottom: -52px;
    width: 60%;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .ruka {
    position: absolute;
    left: -30px;
    display: block;
    bottom: -52px;
    width: 53%;
    max-width: 600px;
  }
  .logoBig {
  }
  .welcomeBlock {
    text-align: center;
    right: 15%;
    top: 20%;
  }
  .welcomeText {
    font-size: 16px;
    line-height: 36px;
    color: #fff;
    display: inline-block;

    .welcomeTitle {
      font-weight: 900;
      font-size: 104px;
      line-height: 108px;
      color: #fff;
    }
  }
  .welcomeDescription {
    padding-bottom: 8px;
    font-size: 14px;
    line-height: 22px;
    color: #f1ebf5;
    height: 100%;
    align-self: flex-end;
    width: 50%;

    > span {
      float: right;
      max-width: 355px;
      display: inline-block;
    }
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .welcomeBlock {
    text-align: center;
    right: 20%;
    top: 20%;
  }

  .ruka {
    position: absolute;
    left: -25px;
    display: block;
    bottom: -56px;
    width: 60%;
    max-width: 650px;
  }
}

@media (min-width: 1500px) {
  .welcomeBlock {
    text-align: center;
    right: 25%;
    top: 20%;
  }

  .ruka {
    position: absolute;
    left: -25px;
    display: block;
    bottom: -56px;
    width: 60%;
    max-width: 650px;
  }
}