.flex {
  display: flex;

  &.align-center {
    align-items: center;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.align-end {
    align-items: flex-end;
  }

  &.align-stretch {
    align-items: stretch;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-between {
    justify-content: space-between;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  &.justify-stretch {
    justify-content: stretch;
  }

  &.flex-wrap {
    flex-wrap: wrap;
  }

  &.flex-nowrap {
    flex-wrap: nowrap !important;
  }

  &.flex-column {
    flex-direction: column !important;
  }

  &.flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}

.pointer {
  cursor: pointer;
}

.bold {
  font-weight: bold !important;
  font-family: $font-bold !important;
}

.full-width {
  width: 100%;
}

.display-block {
  display: block !important;
}

.relative {
  position: relative;
}

.uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}
