@import '../core/variables';


@media (max-width: 576px) {
  .appBanner {
    position: relative;
    height: 406px;
    width: 100%;
    overflow: hidden;

    a {
      line-height: 0;
    }
  }

  .bar {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    position: relative;

    .title {
      color: #1D1D1D;
      font-family: Gilroy;
      font-size: 12px;
      font-style: normal;
      font-weight: 800;
      line-height: 16px;
    }
    .description {
      color: rgba(29, 29, 29, 0.54);
      font-family: Gilroy;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 13px; /* 130% */
    }

    .button {
      display: inline-block;
      border-radius: 8px;
      border: 1px solid #DCDEDF;
      padding: 10px;
      margin-left: 20px;
      color: #3547FD;
      text-align: center;
      font-family: Gilroy;
      font-size: 12px;
      font-style: normal;
      font-weight: 800;
      line-height: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      float: right;
    }

    .close {
      width: 24px;
      height: 24px;
      background: url(/images-local/icon/close2.svg) no-repeat;
    }
    .logo {
      margin: 0 15px 0 15px;
      line-height: 0;
      img {
        width: 50px;
        height: 50px;
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .appBanner {
    position: relative;
    height: 406px;
    width: 100%;
    overflow: hidden;
  }

  .bar {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      color: #1D1D1D;
      font-family: Gilroy;
      font-size: 12px;
      font-style: normal;
      font-weight: 800;
      line-height: 16px;
    }
    .description {
      color: rgba(29, 29, 29, 0.54);
      font-family: Gilroy;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 13px; /* 130% */
    }

    .button {
      display: inline-block;
      border-radius: 8px;
      border: 1px solid #DCDEDF;
      padding: 10px;
      margin-left: 20px;
      color: #3547FD;
      text-align: center;
      font-family: Gilroy;
      font-size: 12px;
      font-style: normal;
      font-weight: 800;
      line-height: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      float: right;
    }

    .close {
      width: 24px;
      height: 24px;
      background: url(/images-local/icon/close2.svg) no-repeat;
    }
    .logo {
      margin: 0 15px 0 15px;
      line-height: 0;
      img {
        width: 50px;
        height: 50px;
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .appBanner {
    display: none;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1024px) {
  .bar {
    display: none;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {

}