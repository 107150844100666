@import '../core/variables';

@media (max-width: 576px) {
  .errorMessage {
    margin-bottom: 10px;
    padding-left: 2px;
    height: 11px;
    font-size: 11px;
    color: red;
    margin-top: 5px;
    display: block;
    overflow: hidden;
  }
  .buttonLoader {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    border-width: 2px;
    border-style: solid;
    border-image: initial;
    border-color: #fff #fff transparent;
    display: inline-block;
    animation: 0.75s linear 0s infinite normal both running cliploader;
    background: transparent !important;
    position: relative;
    top: 6px;
  }

  @keyframes cliploader {
    0% {
      transform: rotate(0deg) scale(1)
    }
    50% {
      transform: rotate(180deg) scale(0.8)
    }
    100% {
      transform: rotate(360deg) scale(1)
    }
  }

  .iconContainer {
    position: relative;
  }

  .inputSelect {
    max-width: 100%;
    width: 100%;
    font-family: 'Gilroy';

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #605866;

    fieldset {
      font-size: 10px;
      border: 1px solid #F1EBF5;
      box-sizing: border-box;

      legend {
        span {
          padding: 0 10px;
        }
      }
    }

    :global(.MuiInputLabel-outlined) {
      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #605866;
      padding-left: 40px;
    }

    :global(.MuiInputLabel-shrink) {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 800;
      font-size: 10px;
      line-height: 20px;
      text-transform: uppercase;
      color: #605866;
      padding-left: 5px;
    }

    > div {
      height: 48px;
      box-sizing: border-box;
      border-radius: 0;
    }

    :global(.MuiOutlinedInput-input) {
      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 23px;
      color: #605866;
      padding-left: 50px;
    }

    ul li {
      padding: 11px 24px;
    }
  }

  .inputText.multiline {
    > div {
      height: auto;
    }
  }
  .inputText {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #605866;
    width: 100%;

    fieldset {
      font-size: 10px;
      border: 1px solid #F1EBF5;
      box-sizing: border-box;

      legend {
        span {
          padding: 0 10px;
        }
      }
    }

    :global(.MuiInputLabel-outlined) {
      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #605866;
      padding-left: 15px;
    }

    :global(.MuiInputLabel-shrink) {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 800;
      font-size: 10px;
      line-height: 20px;
      text-transform: uppercase;
      color: #605866;
      padding-left: 5px;
    }

    > div {
      height: 48px;
      box-sizing: border-box;
      border-radius: 0;
    }

    input {
      padding: 14px 14px 14px 14px;
      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #37313C;
    }
  }

  .inputIcon {
    position: relative;

    :global(.MuiInputLabel-outlined) {
      padding-left: 40px;
    }
    :global(.MuiInputLabel-shrink) {
      padding-left: 5px;
    }

    input {
      padding: 14px 14px 14px 50px;
    }

    .icon {
      width: 16px;
      height: 16px;
      position: absolute;
      z-index: 100;
      top: 17px;
      left: 16px;
    }
    .iconWeight {
      background: url("/images-local/icon/weight.svg") no-repeat center;
    }
    .iconCalories {
      background: url("/images-local/icon/calories.svg") no-repeat center;
    }
    .iconGrowth {
      background: url("/images-local/icon/growth.svg") no-repeat center;
    }
    .iconDate {
      background: url("/images-local/icon/appointments.svg") no-repeat center;
    }
    .iconUser {
      background: url("/images-local/icon/user.svg") no-repeat center;
    }
    .iconEmail {
      background: url("/images-local/icon/envelope.svg") no-repeat center;
    }
    .iconPassword {
      background: url("/images-local/icon/key.svg") no-repeat center;
    }
    .iconWaist {
      background: url("/images-local/icon/waist.svg") no-repeat center;
    }
    .iconThigh {
      background: url("/images-local/icon/thigh.svg") no-repeat center;
    }
    .iconNeck {
      background: url("/images-local/icon/neck.svg") no-repeat center;
    }
    .iconChest {
      background: url("/images-local/icon/chest.svg") no-repeat center;
    }
    .iconScanner {
      background: url("/images-local/icon/scanner.svg") no-repeat center;
    }
    .iconRightLeg {
      background: url("/images-local/icon/right-leg.svg") no-repeat center;
    }
    .iconLeftLeg {
      background: url("/images-local/icon/left-leg.svg") no-repeat center;
    }
    .iconRightHand {
      background: url("/images-local/icon/right-hand.svg") no-repeat center;
    }
    .iconLeftHand {
      background: url("/images-local/icon/left-hand.svg") no-repeat center;
    }
  }

  .selectIcon {
    position: relative;
    .icon {
      width: 16px;
      height: 16px;
      position: absolute;
      z-index: 100;
      top: 17px;
      left: 16px;
    }

    .iconGender {
      background: url("/images-local/icon/gender.svg") no-repeat center;
    }

    .iconActivity {
      background: url("/images-local/icon/activity.svg") no-repeat center;
    }

    .iconWeight {
      background: url("/images-local/icon/weight.svg") no-repeat center;
    }

    .iconPlace {
      background: url("/images-local/icon/place.svg") no-repeat center;
    }

    .iconExperience {
      background: url("/images-local/icon/experience.svg") no-repeat center;
    }

  }

  .button {
    width: 100%;
    height: 48px;
    line-height: 48px;
    margin-top: 24px;
    text-align: center;
    background: #3547FD;
    box-shadow: 8px 8px 16px rgba(153, 153, 153, 0.12);
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    color: #FFFFFF;
    cursor: pointer;
  }

  .checkboxLabel {
    span {
      display: block;
      padding-left: 34px;
      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 16px;
      color: #605866;

      a {
        font-weight: bold;
        text-decoration: underline;
        color: #605866;
      }

      a:visited {
        color: #605866;
        text-decoration: underline;
      }
    }
  }

  .checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
    cursor: pointer;
  }

  .checkbox + div {
    user-select: none;
    cursor: pointer;
  }

  .checkbox + div::before {
    content: '';
    display: block;
    float: left;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #F1EBF5;
    border-radius: 0;
    margin-top: 5px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    cursor: pointer;
  }

  .checkbox:checked + div::before {
    border-color: #3547FD;
    background: url('/images-local/icon/checkbox.svg') no-repeat center;
  }

  .menuItem {
    max-width: 100%;
    white-space: inherit !important;
    width: 100%;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .errorMessage {
    margin-bottom: 10px;
    padding-left: 2px;
    height: 14px;
    font-size: 11px;
    color: red;
    margin-top: 5px;
    display: block;
  }
  .buttonLoader {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    border-width: 2px;
    border-style: solid;
    border-image: initial;
    border-color: #fff #fff transparent;
    display: inline-block;
    animation: 0.75s linear 0s infinite normal both running cliploader;
    background: transparent !important;
    position: relative;
    top: 6px;
  }

  @keyframes cliploader {
    0% {
      transform: rotate(0deg) scale(1)
    }
    50% {
      transform: rotate(180deg) scale(0.8)
    }
    100% {
      transform: rotate(360deg) scale(1)
    }
  }

  .iconContainer {
    position: relative;
  }

  .inputSelect {
    max-width: 100%;
    width: 100%;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #605866;

    fieldset {
      font-size: 10px;
      border: 1px solid #F1EBF5;
      box-sizing: border-box;

      legend {
        span {
          padding: 0 10px;
        }
      }
    }

    :global(.MuiInputLabel-outlined) {
      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #605866;
      padding-left: 40px;
    }

    :global(.MuiInputLabel-shrink) {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 800;
      font-size: 10px;
      line-height: 20px;
      text-transform: uppercase;
      color: #605866;
      padding-left: 5px;
    }

    > div {
      height: 48px;
      box-sizing: border-box;
      border-radius: 0;
    }

    :global(.MuiOutlinedInput-input) {
      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 23px;
      color: #605866;
      padding-left: 50px;
    }

    ul li {
      padding: 11px 24px;
    }
  }

  .selectMenu {
    :global(.MuiMenu-paper) {
      max-width: 100px;
    }
  }

  .menuItem {
    max-width: 100%;
    white-space: inherit !important;
    width: 100%;
  }
  .inputText.multiline {
    > div {
      height: auto;
    }
  }
  .inputText {
    font-family: 'Gilroy';

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #605866;
    width: 100%;

    fieldset {
      font-size: 10px;
      border: 1px solid #F1EBF5;
      box-sizing: border-box;

      legend {
        span {
          padding: 0 10px;
        }
      }
    }

    :global(.MuiInputLabel-outlined) {
      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #605866;
      padding-left: 40px;
    }

    :global(.MuiInputLabel-shrink) {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 800;
      font-size: 10px;
      line-height: 20px;
      text-transform: uppercase;
      color: #605866;
      padding-left: 5px;
    }

    > div {
      height: 48px;
      box-sizing: border-box;
      border-radius: 0;
    }

    input {
      padding: 14px 14px 14px 14px;

      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #37313C;
    }
  }

  .inputIcon {
    position: relative;

    input {
      padding: 14px 14px 14px 50px;
    }

    .icon {
      width: 16px;
      height: 16px;
      position: absolute;
      z-index: 100;
      top: 17px;
      left: 16px;
    }
    .iconWeight {
      background: url("/images-local/icon/weight.svg") no-repeat center;
    }
    .iconCalories {
      background: url("/images-local/icon/calories.svg") no-repeat center;
    }
    .iconGrowth {
      background: url("/images-local/icon/growth.svg") no-repeat center;
    }
    .iconDate {
      background: url("/images-local/icon/appointments.svg") no-repeat center;
    }
    .iconUser {
      background: url("/images-local/icon/user.svg") no-repeat center;
    }
    .iconEmail {
      background: url("/images-local/icon/envelope.svg") no-repeat center;
    }
    .iconPassword {
      background: url("/images-local/icon/key.svg") no-repeat center;
    }
    .iconWaist {
      background: url("/images-local/icon/waist.svg") no-repeat center;
    }
    .iconThigh {
      background: url("/images-local/icon/thigh.svg") no-repeat center;
    }
    .iconNeck {
      background: url("/images-local/icon/neck.svg") no-repeat center;
    }
    .iconChest {
      background: url("/images-local/icon/chest.svg") no-repeat center;
    }
    .iconScanner {
      background: url("/images-local/icon/scanner.svg") no-repeat center;
    }
    .iconRightLeg {
      background: url("/images-local/icon/right-leg.svg") no-repeat center;
    }
    .iconLeftLeg {
      background: url("/images-local/icon/left-leg.svg") no-repeat center;
    }
    .iconRightHand {
      background: url("/images-local/icon/right-hand.svg") no-repeat center;
    }
    .iconLeftHand {
      background: url("/images-local/icon/left-hand.svg") no-repeat center;
    }
  }

  .selectIcon {
    position: relative;
    .icon {
      width: 16px;
      height: 16px;
      position: absolute;
      z-index: 100;
      top: 17px;
      left: 16px;
    }

    .iconGender {
      background: url("/images-local/icon/gender.svg") no-repeat center;
    }

    .iconActivity {
      background: url("/images-local/icon/activity.svg") no-repeat center;
    }

    .iconWeight {
      background: url("/images-local/icon/weight.svg") no-repeat center;
    }

    .iconPlace {
      background: url("/images-local/icon/place.svg") no-repeat center;
    }


    .iconExperience {
      background: url("/images-local/icon/experience.svg") no-repeat center;
    }

  }

  .button {
    width: 100%;
    height: 48px;
    line-height: 48px;
    margin-top: 24px;
    text-align: center;
    background: #3547FD;
    box-shadow: 8px 8px 16px rgba(153, 153, 153, 0.12);
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    color: #FFFFFF;
    cursor: pointer;
  }

  .checkboxLabel {
    span {
      display: block;
      padding-left: 34px;
      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 16px;
      color: #605866;

      a {
        font-weight: bold;
        text-decoration: underline;
        color: #605866;
      }

      a:visited {
        color: #605866;
        text-decoration: underline;
      }
    }
  }

  .checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
    cursor: pointer;
  }

  .checkbox + div {
    user-select: none;
    cursor: pointer;
  }

  .checkbox + div::before {
    content: '';
    display: block;
    float: left;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #F1EBF5;
    border-radius: 0;
    margin-top: 5px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    cursor: pointer;
  }

  .checkbox:checked + div::before {
    border-color: #3547FD;
    background: url('/images-local/icon/checkbox.svg') no-repeat center;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
}
