@import '../core/variables';


@media (max-width: 576px) {
  .content {
    max-width: 790px;
    background: black;
    color: #fff;
    box-shadow: 8px 8px 16px rgba(153, 153, 153, 0.12);
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    position: absolute;
    border: none !important;
    outline: none !important;

    .close {
      display: block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 10px;
      right: 15px;
      background: url(/images-local/icon/close.svg) no-repeat center;
    }

    .header {
      color: #fff;
      height: 190px;
      background: url(/images-local/ruki.png) no-repeat top center;
      background-size: contain;
      background-color: black;
      font-family: Gilroy;
      font-size: 26px;
      font-weight: 800;
      line-height: 55px;
      letter-spacing: 0em;
      text-align: left;
      display: flex;
      padding: 7px 24px 0;
      span {
        align-self: flex-end;
      }
    }

    .description {
      padding: 10px 24px;
      font-family: Gilroy;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #fff;
      background: black;
    }

    .buttons {
      padding: 10px 24px 50px 24px;
      display: flex;
      justify-content: space-between;
    }

    .buttonLeft {
      padding: 11px 15px;
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.01em;
      background: #3547FD;
      color: #FFFFFF;
      width: 100%;
    }

    .buttonRight {
      display: none;
      padding: 11px 15px;
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.01em;
      background: #3547FD;
      color: #FFFFFF;
      max-width: 49.5%;
      width: 100%;

      span {
        display: none;
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .content {
    border: none !important;
    outline: none !important;
    max-width: 695px;
    color: white;
    background: black;
    box-shadow: 8px 8px 16px rgba(153, 153, 153, 0.12);
    margin: 5% auto;
    width: 90%;
    position: relative;

    .close {
      display: block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 10px;
      right: 15px;
      background: url(/images-local/icon/close.svg) no-repeat center;

    }

    .header {
      background: url(/images-local/ruki.png) no-repeat top center;
      background-size: contain;
      background-color: black;
      height: 280px;

      font-family: Gilroy;
      font-size: 38px;
      font-weight: 800;
      line-height: 92px;
      letter-spacing: 0em;
      text-align: left;

      color: white;
      display: flex;
      padding: 0px 27px 0px;
      span {
        align-self: flex-end;
      }
    }

    .description {
      padding: 0px 27px;
      font-family: Gilroy;
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: left;

      color: white;
    }

    .buttons {
      padding: 30px 24px 30px 24px;
      display: flex;
      justify-content: space-around;
    }

    .buttonLeft {
      border: 1px solid #AEA8B3;

      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.01em;
      background: #fff;
      color: #37313C;
      padding: 14px 10px;
      max-width: 49.5%;
      width: 100%;

      span {
        display: none;
      }
    }

    .buttonRight {
      padding: 14px 15px;
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.01em;
      background: #3547FD;
      color: #FFFFFF;
      max-width: 49.5%;
      width: 100%;

      span {
        display: none;
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .content {

    .buttonLeft {
      max-width: 242px;

      span {
        display: inline-block;
      }
    }

    .buttonRight {
      margin-left: 24px;
      max-width: 242px;

      span {
        display: inline-block;
      }
    }
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {

}