@import '../core/variables';


@media (max-width: 576px) {
  .contentContainer {
    height: 100%;
    margin: 0 auto;
    min-height: 95vh;
    max-width: 88%;
  }

  .noCrumbs {
    padding-top: 20px;
  }

  .breadCrumbs {
    margin-left: 34px;
    margin-top: 16px;

    li {
      display: inline-block;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      color: #37313C;
    }

    a {
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      color: #AEA8B3;
      margin-right: 9px;
    }

    li a:after {
      content: " \B7 ";
      position: relative;
      right: -3px;
    }
  }
  .breadCrumbs {
    margin-left: 21px;
    margin-top: 5px;
    margin-bottom: 19px;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .contentContainer {
    height: 100%;
    margin: 0 auto;
    min-height: 95vh;
    max-width: 88%;
  }

  .noCrumbs {
    padding-top: 20px;
  }

  .breadCrumbs {
    margin-left: 34px;
    margin-top: 16px;

    li {
      display: inline-block;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      color: #37313C;
    }

    a {
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      color: #AEA8B3;
      margin-right: 9px;
    }

    li a:after {
      content: " \B7 ";
      position: relative;
      right: -3px;
    }
  }
  .breadCrumbs {
    margin-left: 21px;
    margin-top: 5px;
    margin-bottom: 19px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .contentContainer {
    max-width: 720px;
    height: 100%;
    margin: 0 auto;
    min-height: 95vh;
  }

  .breadCrumbs {
    margin-left: 24px;
    margin-top: 13px;
    margin-bottom: 31px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .contentContainer {
    max-width: 842px;
    height: 100%;
    margin: 0 auto;
    min-height: 95vh;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .breadCrumbs {
    margin-left: 40px;
    margin-top: 16px;
  }
  .contentContainer {
    max-width: 1100px;
    height: 100%;
    margin: 0 auto;
    min-height: 95vh;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .contentContainer {
    max-width: 1280px;
    height: 100%;
    margin: 0 auto;
    min-height: 95vh;
  }
}