@import '../core/variables';

.footer {
  width: 100%;
  border-top: 1px solid #AEA8B3;
  margin-top: 30px;
}

.footerContainer {
  max-width: 1440px;
  padding: 25px 80px;
  margin: 0 auto 0;
  color: #605866;
  display: flex;
  justify-content: space-between;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
}

.footerLeft {
  display: flex;
}

.menu {
  margin-left: 12px;

  ul {
    display: flex;

    a {
      padding: 0 18px;
      color: #605866;
    }
  }
}

@media (max-width: 992px) {
  .footer {
    display: none;
  }
}
