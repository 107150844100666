body {
  font-family: $font-regular;
  background-color: $white;
  -webkit-font-smoothing: antialiased;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
}

a {
  text-decoration: none;
}

html {
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  background: transparent !important;
  color: #605866 !important;
}


.ql-align-center {
  text-align: center;
}

.ql-align-justify {
  text-align: justify;
}

.ql-align-right {
  text-align: right;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  .ql-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.css-loader {
  position: absolute;
  width: 70px;
  height: 70px;
  border: 6px solid #3547FD;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  left: 50%;
  top: 50%;
  margin-left: -35px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.image-container {
  width: 100%;

  > span {
    position: unset !important;
  }

  .image {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }
}

@media (max-width: 360px) {

}

@media (min-width: 360px) {

}

@media (max-width: 576px) {
  .title-h1, h1 {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
  }

  .title-h2, h2 {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
  }

  .title-h3, h3 {
    font-family: $font-regular;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
  }

  .posts-slider .slick-track {

  }


  .head-slider .slick-slide {
    width: 300px !important;
    height: 340px !important;

    margin-right: 20px;
  }

  .head-slider .slick-slide img {
    width: 300px !important;
    height: 340px !important;
  }
}


@media (min-width: 576px) {
  .title-h1, h1 {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
  }

  .title-h2, h2 {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
  }

  .title-h3, h3 {
    font-family: $font-regular;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
  }

  .posts-slider .slick-track {
    padding-bottom: 40px;
  }

  .posts-slider .post-slide {
    width: 327px;
    max-width: 327px;
    height: 380px;
  }

  .head-slider .slick-slide {
    width: 300px !important;
    height: 340px !important;

    margin-right: 24px;
  }

  .head-slider .slick-slide img {
    width: 300px !important;
    height: 340px !important;
  }

  .posts-slider .post-slide {
    width: 45%;
    max-width: 327px;
    height: 380px;
  }

  h1 {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
  }

  .title-h2, h2 {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .posts-slider .post-slide {
    width: auto;
    max-width: 348px;
    height: 380px;
  }

  .title-h2, h2 {
    font-style: normal;
    font-weight: 800;
    font-size: 26px;
    line-height: 36px;
  }

  h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 26px;
    line-height: 32px;
  }

  .head-slider .slick-slide {
    width: 340px !important;
    height: 380px !important;

    margin-right: 24px;
  }

  .head-slider .slick-slide img {
    width: 340px !important;
    height: 380px !important;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  h1 {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 55px;
    line-height: 75px;
  }
  .title-h2, h2 {
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 60px;
    color: #000000;
  }

  .posts-slider .post-slide {
    width: 405px;
    max-width: 405px;
    height: 420px;
  }

  .posts-slider .slick-track {
    padding-bottom: 70px;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .posts-slider .post-slide {
    width: 405px;
    max-width: 360px;
    height: 420px;
  }

  .posts-slider .slick-track {
    padding-bottom: 70px;
  }

  .head-slider .slick-slide {
    width: 340px !important;
    height: 380px !important;
    margin-right: 24px;
  }

  .head-slider .slick-slide img {
    width: 340px !important;
    height: 380px !important;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1400px) {
  .posts-slider .post-slide {
    width: 405px;
    max-width: 405px;
    height: 420px;
  }
}

.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}