@import '../../../core/variables';

@media (max-width: 576px) {
  .logo {
    cursor: pointer;
    width: 120px;
    height: 20px;
    margin-top: 6px;
    margin-left: 29px;
  }

  .selected {
    background: rgba(96, 110, 255, 0.1);

    a {
      color: #3547FD !important;
    }
  }

  .mobileMenuMain {
    padding-top: 50px;
    top: 0;
    a {
      width: 100%;
    }
    .menuWrapper {
      display: flex !important;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;
    }

    .menuLogout {
      border-bottom: 1px solid #C4C4C4;
      cursor: pointer;
      padding: 15px 24px;
    }

    .backToBlog {
      a {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #AEA8B3;
      }
    }
  }

  .menu {
    display: none;
  }

  .loginBtn {
    display: none;
  }

  .mobileMenu {
    display: inline-block;
    padding: 4px 4px 4px 4px;
    height: 100%;
    margin-left: 21px;
  }

  .leftWrapper {
    display: flex;
    justify-content: center;
  }

  .logo {
    cursor: pointer;
    margin-left: 27px;
  }

  .rightBar {
    height: 100%;
    padding-right: 0;
  }

  .topBar {
    height: 57px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #3547FD;
  }

  .searchIcon {
    margin-top: 6px;
    display: inline-block;
    padding: 8px;
    margin-right: 8px;
    cursor: pointer;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .mobileMenuMain {
    padding-top: 50px;
    top: 0;
    a {
      width: 100%;
    }
    .menuWrapper {
      display: flex !important;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;
    }

    .menuLogout {
      border-bottom: 1px solid #C4C4C4;
      cursor: pointer;
      padding: 15px 24px;
    }

    .backToBlog {
      a {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #AEA8B3;
      }
    }
  }

  .logo {
    cursor: pointer;
    width: 120px;
    height: 20px;
    margin-top: 6px;
    margin-left: 29px;
  }

  .menu {
    display: none;
  }

  .mobileMenu {
    display: inline-block;
    padding: 4px 4px 4px 4px;
    height: 100%;
    margin-left: 21px;
  }

  .leftWrapper {
    display: flex;
    justify-content: center;
  }

  .logo {
    margin-left: 27px;
  }

  .rightBar {
    padding-right: 0;
    height: 100%;
  }

  .topBar {
    height: 57px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #3547FD;
  }

  .searchIcon {
    display: inline-block;
    padding: 8px;
    margin-right: 8px;
    cursor: pointer;
  }

  .loginBtn {
    cursor: pointer;
    display: none;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.01em;
    padding: 10px 31px;
    color: #2F2A32;
    border: 1px solid #2F2A32;
    box-sizing: border-box;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .menu {
    display: none;
  }
  .loginBtn {
    display: inline-block;
  }
  .logo {
    margin-top: 0;
  }

  .mobileMenu {
    display: inline-block;
    padding: 4px 4px 4px 4px;
    height: 100%;
    margin-left: 21px;
  }

  .leftWrapper {
    display: flex;
    justify-content: center;
  }

  .topBar {
    height: 57px;
  }

  .logo {
    margin-left: 27px;
  }

  .logo {
    width: 191px;
    height: 32px;
    margin-left: 26px;
    cursor: pointer;
  }

  .topBar {
    height: 64px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .rightBar {
    display: flex;
    align-items: center;
    padding-right: 24px;
  }

  .searchIcon {
    display: inline-block;
    padding: 8px;
    cursor: pointer;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .menu {
    list-style-type: none;
    display: flex;
    position: relative;
    top: 0px;
    font-weight: 500;
    margin-right: 40px;

    > li {
      margin-left: 30px;
      position: relative;

      a {
        line-height: 19px;
        font-size: 16px;
        font-family: Gilroy;
        font-style: normal;
        font-weight: normal;
        color: #D3D7FF;
      }
    }

    .selected {
      a {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
      }
    }
  }
  .mobileMenu {
    display: none;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .logo {
    width: 191px;
    height: 32px;
    margin-left: 40px;
    cursor: pointer;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
}
