@font-face {
    font-family: Gilroy;
    src: url("/fonts/Gilroy-Regular.ttf") format("opentype");
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: Gilroy;
    src: url("/fonts/Gilroy-Medium.ttf") format("opentype");
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: Gilroy;
    src: url("/fonts/Gilroy-SemiBold.ttf") format("opentype");
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: Gilroy;
    src: url("/fonts/Gilroy-Bold.ttf") format("opentype");
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: Gilroy;
    src: url("/fonts/Gilroy-ExtraBold.ttf") format("opentype");
    font-weight: 800;
    font-display: swap;
}

@font-face {
    font-family: Gilroy;
    src: url("/fonts/Gilroy-Black.ttf") format("opentype");
    font-weight: 900;
    font-display: swap;
}



